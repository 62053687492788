import { Permission } from '../modules/auth/core/_models'
import { useTranslation } from 'react-i18next'
/**
 * Helper to produce an array of enum values.
 */
export function enumToArray(enumeration: { [x: string]: any }) {
  return Object.values(enumeration)
    .filter((key) => isNaN(Number(key)))
    .map((key) => enumeration[key])
    .filter((val) => typeof val === 'number' || typeof val === 'string')
}

// Function that simulates fetching a permission from remote server
export const fetchPermission =
  (user: any) =>
  async (permission: Permission): Promise<boolean> => {
    // Simulate a delay from a request
    await new Promise((resolve) => setTimeout(resolve, 1000))
    return user.permission.includes(permission)
  }

export const randomBetween = (min: number, max: number) =>
  min + Math.floor(Math.random() * (max - min + 1))

export function getSteps(userRole: any) {
  const { t } = useTranslation()
  if (userRole === 'guest') {
    return [
      // {
      //   element: '.project',
      //   intro: t('placeholder_project_table'),
      // },
      {
        element: '.project_overview',
        intro: t('placeholder_project_overview'),
      },
    ]
  } else if (userRole === 'user') {
    return [
      {
        element: '.device_table',
        intro: t('placeholder_device_table'),
      },
      {
        element: '.filter_device',
        intro: t('placeholder_filter_device'),
      },
      {
        element: '.project',
        intro: t('placeholder_project_table'),
      },
    ]
  } else {
    return [
      {
        element: '.device_table',
        intro: t('placeholder_device_table'),
      },
      {
        element: '.filter_device',
        intro: t('placeholder_filter_device'),
      },
      // {
      //   element: '.project',
      //   intro: t('placeholder_project_table'),
      // },
      {
        element: '.add_project',
        intro: t('placeholder_add_project'),
      },
      {
        element: '.project_archive',
        intro: t('placeholder_project_archiveren'),
      },
      {
        element: '.project_overview',
        intro: t('placeholder_project_overview'),
      },
    ]
  }
}
