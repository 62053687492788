import React, { FC, useMemo, useState, useEffect } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { ThemeModeComponent } from '../../../_metronic/assets/ts/layout'
import { useThemeMode } from '../../../_metronic/partials'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import moment from 'moment'

createTheme(
  'solarized',
  {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      default: '#002b36',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  },
  'dark'
)

createTheme('dark', {
  background: {
    default: 'transparent',
  },
})

type Props = {
  columns: Array<any>
  data: Array<any>
  height: string
  styles?: {
    row: {
      minHeight: string
    }
  }
  selectableRows: boolean
  pagination: boolean
  handleSelect?: (state: any) => void | undefined
  handleRowClicked?: (state: any) => void | undefined
  usedDevices?: any
  handleDisabled?: any
  handleSelected?: any
  showCheckbox: boolean
  showConfigStatus: boolean
}

const SuperTableStatic: FC<Props> = ({
  columns,
  data,
  height,
  selectableRows,
  handleSelect,
  handleRowClicked,
  pagination,
  styles,
  handleDisabled,
  handleSelected,
  showCheckbox = true,
  showConfigStatus = false,
}) => {
  const [processedData, setProcessedData] = useState<any[]>([])

  let { mode } = useThemeMode()
  if (mode === 'system') {
    mode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'
  }

  const isContactOutdated = (lastContactTimestamp: string) => {
    const now = new Date()
    const lastContact = new Date(lastContactTimestamp)
    const timeDiff = (now.getTime() - lastContact.getTime()) / (1000 * 60)

    return timeDiff > 60 // Returns true if the last contact was more than 1 hour ago
  }

  const getStatusDetails = (synced: boolean, charge: number, lastContactTimestamp: string) => {
    // Sync status: Red if the last contact is more than 1 hour ago and sync is not OK
    const outdated = isContactOutdated(lastContactTimestamp)
    const syncState = synced ? (
      'Sync OK'
    ) : outdated ? (
      <b>Sync critical (No contact &gt; 1 hour)</b>
    ) : (
      <b>Sync not OK</b>
    )

    // Battery status
    let batteryState
    if (charge > 80) {
      batteryState = 'Battery OK'
    } else if (charge > 30) {
      batteryState = <b>Battery needs attention - make sure it&apos;s charging.</b>
    } else {
      batteryState = <b>Battery critical - make sure it&apos;s charging.</b>
    }

    return { syncState, batteryState, outdated }
  }

  const calculateDeviceHealth = (
    synced: boolean,
    charge: number,
    outdated: boolean
  ): HealthState => {
    const syncState: HealthState = synced ? 'Green' : outdated ? 'Red' : 'Orange'
    const batteryState: HealthState = charge > 80 ? 'Green' : charge > 30 ? 'Orange' : 'Red'

    // Determine overall health state
    if (batteryState === 'Red' || syncState === 'Red') {
      return 'Red'
    } else if (batteryState === 'Orange' || syncState === 'Orange') {
      return 'Orange'
    } else {
      return 'Green'
    }
  }

  type HealthState = 'Red' | 'Orange' | 'Green'

  const customSort = (rows: any, selector: any, direction: any) => {
    const healthOrder = { Red: 1, Orange: 2, Green: 3 } // Red is highest priority

    return rows.sort((a: any, b: any) => {
      const aHealth: HealthState = a.healthState in healthOrder ? a.healthState : 'Green'
      const bHealth: HealthState = b.healthState in healthOrder ? b.healthState : 'Green'

      // Compare health states first
      if (aHealth !== bHealth) {
        return direction === 'desc'
          ? healthOrder[aHealth] - healthOrder[bHealth]
          : healthOrder[bHealth] - healthOrder[aHealth]
      }

      // If health state is the same, sort by the original selector field (secondary sort)
      const aField = selector(a)
      const bField = selector(b)

      let comparison = 0
      if (aField > bField) {
        comparison = 1
      } else if (aField < bField) {
        comparison = -1
      }

      return direction === 'desc' ? comparison * -1 : comparison
    })
  }

  useEffect(() => {
    if (!data) return

    const processed = data.map((item, index) => {
      let statusColor = ''
      let tooltipContent = null

      if (showConfigStatus) {
        const { syncState, batteryState, outdated } = getStatusDetails(
          item.config_status?.synced,
          item.charge,
          item.last_contact_timestamp
        )

        // Calculate the health state based on sync and battery status
        const healthState: HealthState = calculateDeviceHealth(
          item.config_status?.synced,
          item.charge,
          outdated
        )

        tooltipContent = (
          <div>
            <div>Sync Status: {syncState}</div>
            <div>Battery Status: {batteryState}</div>
          </div>
        )

        // Set status color based on health state
        if (healthState === 'Green') {
          statusColor = 'rgb(80, 205, 137)' // Green for OK
        } else if (healthState === 'Orange') {
          statusColor = 'rgb(255, 199, 0)' // Orange for warning
        } else {
          statusColor = 'rgb(241, 65, 108)' // Red for critical
        }

        return {
          ...item,
          healthState,
          statusColor,
          tooltipContent,
          rowIndex: index,
        }
      }

      // Return the item unchanged if showConfigStatus is false
      return {
        ...item,
        rowIndex: index,
      }
    })

    setProcessedData(processed)
  }, [data, showConfigStatus])

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: '10px',
        paddingRight: '5px',
        fontSize: '0.99rem',
      },
    },
    cells: {
      style: {
        paddingLeft: '10px',
        paddingRight: '5px',
        fontSize: '0.92rem',
        wordBreak: 'break-word',
      },
    },
  }

  const extendedColumns = useMemo(() => {
    const cols = [
      showConfigStatus && {
        // Health state
        name: 'Health',
        id: 'healthState',
        selector: (row: any) => row.healthState,
        cell: (row: any) => (
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id={`tooltip-${row.rowIndex}`}>{row.tooltipContent}</Tooltip>}
          >
            <div
              style={{
                borderLeft: `5px solid ${row.statusColor}`,
                padding: '10px',
                cursor: 'pointer',
              }}
            ></div>
          </OverlayTrigger>
        ),
        width: '5px',
      },
      ...columns,
      // Sync status
      showConfigStatus && {
        name: 'Sync Status',
        id: 'Sync status',
        selector: (row: any) => row.config_status?.synced,
        cell: (row: any) => {
          // show config status only when device is linked to project and sync info is available
          if (row.project_id && row?.config_status !== undefined) {
            // Determine icon and color based on sync state
            let icon = ''
            let label = ''
            let color = ''
            let tooltipText = ''
            if (row.config_status?.synced) {
              icon = 'fa-check-circle' // Green for OK
              label = 'Sync OK'
              color = 'green'
              tooltipText = 'The device configuration is up-to-date.'
            } else if (
              row.config_status?.session > 0 ||
              row.config_status?.alarm > 0 ||
              row.config_status?.filter > 0 ||
              row.config_status?.time > 0 ||
              row.config_status?.start_measuring > 0
            ) {
              //one or more commands have failed
              icon = 'fa-times-circle' // Red for critical (No contact > 1 hour)
              label = 'Synchronisation failed'
              color = 'red'
              tooltipText = 'Synchronisation has failed. Update project to try again.'
            } else {
              icon = 'fa-refresh' // Orange for warning
              label = 'Synchronizing'
              color = 'orange'
              tooltipText =
                'Updating device configuration, this could take some time. Attention needed if sync does not complete within an hour.'
            }

            return (
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id={`tooltip-sync-${row._id}`}>{tooltipText}</Tooltip>}
              >
                <div>
                  <i className={`fas ${icon}`} style={{ color }}></i>
                  <span style={{ marginLeft: '8px' }}>{label}</span>
                </div>
              </OverlayTrigger>
            )
          }
        },
        width: '150px',
      },
    ].filter(Boolean)
    return cols
  }, [columns, showConfigStatus])

  return selectableRows ? (
    <>
      <DataTable
        columns={extendedColumns}
        data={processedData}
        pagination={pagination}
        selectableRows={showCheckbox}
        responsive={true}
        striped={true}
        fixedHeader
        fixedHeaderScrollHeight={height}
        customStyles={customStyles}
        theme={mode === 'dark' ? 'solarized' : ''}
        onSelectedRowsChange={handleSelect}
        onRowClicked={handleRowClicked}
        selectableRowDisabled={handleDisabled}
        selectableRowsHighlight={true}
        sortFunction={customSort}
        defaultSortFieldId='healthState'
        defaultSortAsc={false}
      />
    </>
  ) : (
    <>
      <DataTable
        columns={extendedColumns}
        data={processedData}
        pagination={pagination}
        responsive={true}
        striped={true}
        fixedHeader
        fixedHeaderScrollHeight={height}
        customStyles={customStyles}
        theme={mode === 'dark' ? 'solarized' : ''}
        onRowClicked={handleRowClicked}
        selectableRowDisabled={handleDisabled}
      />
    </>
  )
}

export { SuperTableStatic }
