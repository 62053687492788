import React, { useState, useTransition } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword } from '../core/_requests'
import { useTranslation } from 'react-i18next'

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const search = useLocation().search
  const token = new URLSearchParams(search).get('token')

  const initialValues = {
    password: '',
    cpassword: '',
    token: token,
  }

  const passwordSchema = Yup.object().shape({
    password: Yup.string()
      .required('New password is required')
      .test('check-password', t('password_error'), checkPassword),
    cpassword: Yup.string()
      .required('Password confirmation is required')
      .when('password', {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          'Confirm password and password did not match'
        ),
      }),
  })

  /*  const user = useSelector((state) => state.auth.user, shallowEqual)
    useEffect(() => {}, [user]) */

  const formik = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        resetPassword(values)
          .then(() => {
            setStatus('Password Reset successfully! Login again with new password.')
            setHasErrors(false)
            setLoading(false)
            setTimeout(() => {
              navigate('/auth/login')
            }, 3000)
          })
          .catch(() => {
            setHasErrors(true)
            setSubmitting(false)
            setStatus("Can't update password")
            setLoading(false)
          })
      }, 1000)
    },
  })
  function checkPassword(password: any) {
    // console.log(password)
    const isLongEnough = password && password.length >= 14
    const hasUppercase = /[A-Z]/.test(password)
    const hasLowercase = /[a-z]/.test(password)
    const hasNumber = /\d/.test(password)
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password)
    // console.log()
    return isLongEnough && hasUppercase && hasLowercase && hasNumber && hasSymbol
  }
  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Reset Password</h1>
          {/* end::Title */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Password changed successfully</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
          <input
            type='password'
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder='Confirm Password'
            autoComplete='off'
            {...formik.getFieldProps('cpassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.cpassword && formik.errors.cpassword,
              },
              {
                'is-valid': formik.touched.cpassword && !formik.errors.cpassword,
              }
            )}
          />
          {formik.touched.cpassword && formik.errors.cpassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.cpassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
