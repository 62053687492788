import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapperMobile } from '../modules/mobile/dashboard/DashboardWrapperMobile'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { MobileLayout } from '../../_metronic/layout/MobileLayout'
import { isMobile } from 'react-device-detect'

const MobileRouters = () => {
  const InstallerPage = lazy(() => import('../modules/mobile/installer/InstallerPage'))

  return (
    <Routes>
      <Route element={<MobileLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {isMobile && <Route path='auth/*' element={<Navigate to='/installer/install/start' />} />}
        {/* Pages */}
        <Route path='/demo' element={<DashboardWrapperMobile />} />
        <Route
          path='/install/*'
          element={
            <SuspensedView>
              <InstallerPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { MobileRouters }
