import React, { createContext, useContext, ReactNode } from 'react'

// Define the context type
interface MyContextType {
  id_auth: number | undefined
  updateData: (newData: number | undefined) => void
}

// Create the context
const MyContext = createContext<MyContextType | undefined>(undefined)

// Create a custom hook to access the context
export const useMyContext = () => {
  const context = useContext(MyContext)
  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider')
  }
  return context
}

// Create the ContextProvider component
interface MyContextProviderProps {
  children: ReactNode
}

const MyContextProvider: React.FC<MyContextProviderProps> = ({ children }) => {
  const [id_auth, setData] = React.useState<number | undefined>(NaN)

  const updateData = (newData: number | undefined) => {
    setData(newData)
  }

  return <MyContext.Provider value={{ id_auth, updateData }}>{children}</MyContext.Provider>
}

export default MyContextProvider
