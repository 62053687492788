const QUERIES = {
  USERS_LIST: 'users-list',
  SESSIONSTART_LIST: 'session-start-list',
  SESSIONEND_LIST: 'session-end-list',
  DATASUMMARY_LIST: 'data-summary-list',
  METADATA_LIST: 'meta-data-list',
  KBDATA_LIST: 'kb-data-list',
}

export { QUERIES }
