/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Topbar } from './Topbar'

export function HeaderWrapperMobile() {
  const { config, classes, attributes } = useLayout()
  const { header, aside } = config

  const navigate = useNavigate()

  const handleBackButton = () => {
    navigate(-1)
    if (localStorage) {
      localStorage.setItem('backButtonCall', 'true')
    }
  }

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
          <button className='btn btn-link d-lg-none' onClick={() => handleBackButton()}>
            <KTSVG
              path={toAbsoluteUrl('/media/icons/duotune/arrows/arr002.svg')}
              className='svg-icon-muted svg-icon-2hx'
            />
          </button>
        </div>

        <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
          <Link to='/' className='d-lg-none'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/profound_services.png')}
              className='h-30px'
            />
          </Link>
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
