import React, { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { ResetPassword } from './components/ResetPassword'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed bg-right-bottom'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/auth/vibra5_background_image.png')})`,
      }}
    >
      <div className='position-md-absolute start-100px top-0 w-md-15'>
        <img
          src='/media/auth/profound_logo_background_image.png'
          alt='profound_logo_background_image'
          className='mw-100 w-150px w-md-auto d-block mx-auto'
          width={495}
        />
      </div>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
      <Route path='reset_password' element={<ResetPassword />} />
    </Route>
  </Routes>
)

export { AuthPage }
