import moment, { Moment } from 'moment-timezone'

const dateFormat = 'MMM D yyyy'

export function dateToString(
  date: string | Moment | null | undefined,
  timezone = 'Europe/Amsterdam' as string
) {
  moment.tz.setDefault(timezone)
  const formatedDate = moment(date)
  let displayDate = formatedDate.format(dateFormat)
  // console.log(formatedDate)
  const today = moment()
  switch (true) {
    case formatedDate.isSame(today, 'minutes'):
      displayDate = 'Online'
      break
    case formatedDate.isAfter(today.subtract(1, 'minutes'), 'minutes') &&
      !formatedDate.isAfter(today.subtract(2, 'minutes'), 'minutes'):
      displayDate = 'Just Now'
      break
    case formatedDate.isAfter(today.subtract(2, 'minutes'), 'minutes') &&
      !formatedDate.isAfter(today.subtract(5, 'minutes'), 'minutes'):
      displayDate = '2 min ago'
      break
    case formatedDate.isAfter(today.subtract(5, 'minutes'), 'minutes') &&
      !formatedDate.isAfter(today.subtract(10, 'minutes'), 'minutes'):
      displayDate = '5 min ago'
      break
    case formatedDate.isAfter(today.subtract(10, 'minutes'), 'minutes') &&
      !formatedDate.isAfter(today.subtract(11, 'minutes'), 'minutes'):
      displayDate = '10 min ago'
      break
    case formatedDate.isSame(today, 'day'):
      displayDate = `Today ${formatedDate.format('HH:mm')}`
      break
    case formatedDate.isSame(today.subtract(1, 'days'), 'day'):
      displayDate = `Yesterday ${formatedDate.format('HH:mm')}`
      break
    default:
      break
  }
  return displayDate
}

export function dateToStringForLogs(
  date: string | Moment | null | undefined,
  timezone = 'Europe/Amsterdam' as string
) {
  moment.tz.setDefault(timezone)
  const formatedDate = moment(date).format('MMM DD YYYY HH:mm:ss')
  return formatedDate
}

export function timeToString(date: string | Moment | null | undefined) {
  if (date) {
    if (typeof date === 'string' && date.includes(':')) {
      const parts: string[] = date.split(':')
      const [hours, minutes]: string[] = parts
      const formatedDate: Moment = moment()
        .set('hour', parseInt(hours, 10))
        .set('minute', parseInt(minutes, 10))
      return formatedDate.isValid() ? formatedDate.format('HH:mm') : String(date)
    } else {
      const formatedDate = moment(date)
      return formatedDate.isValid() ? formatedDate.format('HH:mm') : String(date)
    }
  }
}
