import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

const fallbackLng = 'en'
const lang = window.localStorage.i18nextLng || i18next.language || fallbackLng

// We can localize to any language and any number of languages.
const backendOptions = {
  type: 'backend',
  crossDomain: false,
  allowMultiLoading: true,
  loadPath: `${process.env.REACT_APP_REST_API_URL}/languages/${lang}`,
}

i18next
  .use(initReactI18next)
  .use(HttpApi) // Registering the back-end plugin
  .init({
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['en', 'nl'],
    fallbackLng: fallbackLng,
    nonExplicitSupportedLngs: true,
    debug: process.env.NODE_ENV === 'development',
    backend: backendOptions,
  })

export default i18next
