import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { DeviceInfoTable } from './components/DeviceInfoTable'
import { ProjectInfoTable } from './components/ProjectInfoTable'
import {
  getAuthData,
  getDeviceDataRows,
  updateAuthData,
} from '../../modules/dashboard/core/_requests'
import { useQuery } from 'react-query'
import { deleteSelectedProjects } from '../../modules/apps/project/core/_requests'
import PermissionProvider from '../../PermissionProvider/PermissionProvider'
import Restricted from '../../PermissionProvider/Restricted'
import { useAuth } from '../auth'
import { fetchPermission, getSteps } from '../../helpers/CommonHelper'
import { useTranslation } from 'react-i18next'
import { Steps } from 'intro.js-react'
import 'intro.js/introjs.css'
import IntroJSProvider, { useMyContext } from './IntroJSProvider'
import { BlockLoading } from '../../components/loaders/BlockLoading'

interface filterOptions {
  isArchive: boolean
}

const DashboardPage: FC = () => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()
  const initFilter = { isArchive: false }
  const { id_auth, updateData } = useMyContext()
  const [showDemo, setShowDemo] = useState<boolean>(false)
  const [filterOption, setFilterOption] = useState<filterOptions>(initFilter)
  const { isLoading, isRefetching, data, refetch } = useQuery(
    'dashboard-list',
    () => {
      return getDeviceDataRows(filterOption)
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )
  const { data: authData } = useQuery(
    'auth',
    async () => {
      return await getAuthData()
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
  const handleDeleteProjects = (ids: any) => {
    deleteSelectedProjects(ids)
    refetch()
  }

  useEffect(() => {
    updateData(currentUser?._id)
    id_auth === currentUser?._id ? setShowDemo(true) : setShowDemo(false)
  }, [])
  const handleFilter = (filter: any) => {
    setFilterOption(filter)
    refetch()
  }
  const [stepsEnabled, setStepsEnabled] = useState<boolean>(true)
  const [deviceEnabled, setDeviceEnabled] = useState<boolean>(false)
  const [initialStep, setInitialStep] = useState<number>(0)
  const [projectEnabled, setProjectEnabled] = useState<boolean>(false)
  const [steps, setSteps] = useState<Array<any>>(getSteps(currentUser?.role))
  const devices = [{ element: '.device_table', intro: t('placeholder_device_table') }]
  const projects = [{ element: '.project', intro: t('placeholder_project_table') }]
  const handlePlaceholders = () => {
    const placeholder_screen = { placeholder_screen: { dashboard: 1, add_project: 0 } }
    if (authData?.placeholder_screen?.dashboard === 0) {
      updateAuthData(placeholder_screen)
    }
  }
  const onExit = () => {
    setStepsEnabled(false)
    setDeviceEnabled(false)
    setProjectEnabled(false)
    authData?.placeholder_screen?.dashboard === 0 && handlePlaceholders()
  }
  const handleStepEnabled = () => {
    setDeviceEnabled(true)
  }
  const handleProjectPlaceholder = () => {
    setProjectEnabled(true)
  }
  return (
    <>
      <UserAgreement />
      {!isLoading && data && (
        <div className=''>
          <PermissionProvider fetchPermission={fetchPermission(currentUser)}>
            <Restricted to='dashboard.headerinfo.view'>
              <div className='d-flex justify-content-between flex-column flex-xl-row controls'>
                <div className='leftTable'>
                  <table
                    className='table table-striped border border-dark border-top-0 border-left-0 border-right-0'
                    cellPadding={0}
                    cellSpacing={0}
                  >
                    <tbody>
                      <tr>
                        <td className='border border-dark py-2 px-4 border-bottom-0'>
                          {t('callibration_due_in')}:&nbsp;
                          <strong> {data?.headerInfo?.calibration} </strong>
                        </td>
                        <td className='border border-dark py-2 px-4 border-bottom-0 border-left-0'>
                          {t('utilization_rate')}:&nbsp;
                          <strong> {data?.headerInfo?.utilization} </strong>
                        </td>
                        <td className='border border-dark py-2 px-4 border-bottom-0 border-left-0'>
                          {t('warning')}:&nbsp;
                          <strong> {data?.headerInfo?.warnings} </strong>
                        </td>
                        <td className='border border-dark py-2 px-4 border-bottom-0 border-left-0'>
                          {t('issues')}:&nbsp;
                          <strong> {data?.headerInfo?.issues} </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className='d-flex flex-xl-column'
                  data-kt-user-table-toolbar='base'
                  data-bs-target='#collapseTarget'
                  data-bs-toggle='collapse'
                  title='Coming Soon'
                >
                  <a
                    href='#'
                    className='btn btn-sm btn-primary text-right mb-xl-2 px-4 px-sm-5 supervier'
                  >
                    {t('superviser_options')}&nbsp;
                    <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-2' />
                  </a>
                </div>
              </div>
            </Restricted>
          </PermissionProvider>

          <PermissionProvider fetchPermission={fetchPermission(currentUser)}>
            {currentUser?.role === 'user' ? (
              <>
                <Restricted to='dashboard.projectoverview.view'>
                  {currentUser?.role === 'user' &&
                    authData?.placeholder_screen?.dashboard === 0 && (
                      <Steps
                        enabled={stepsEnabled}
                        steps={steps}
                        initialStep={initialStep}
                        onExit={onExit}
                      />
                    )}
                  <ProjectInfoTable
                    data={data.projectData}
                    handleDeleteProjects={handleDeleteProjects}
                    filterOption={filterOption}
                    setFilterOption={handleFilter}
                    isRefetching={isRefetching}
                    handleProjectPlaceholder={handleProjectPlaceholder}
                    authData={authData}
                    initialStep={initialStep}
                    onExit={onExit}
                    steps={steps}
                    stepsEnabled={stepsEnabled}
                  />
                </Restricted>

                <Restricted to='dashboard.deviceoverview.view'>
                  <div className=''>
                    <DeviceInfoTable
                      data={data.deviceData}
                      projectData={data.projectData}
                      isRefetching={isLoading}
                      handleStepEnabled={handleStepEnabled}
                      title='1'
                      showCheckbox={false}
                      showConfigStatus={true}
                    />
                  </div>
                </Restricted>
              </>
            ) : (
              <>
                <Restricted to='dashboard.deviceoverview.view'>
                  <div className=''>
                    <DeviceInfoTable
                      data={data.deviceData}
                      projectData={data.projectData}
                      isRefetching={isLoading}
                      handleStepEnabled={handleStepEnabled}
                      title='1'
                      showCheckbox={false}
                      showConfigStatus={true}
                    />
                  </div>
                </Restricted>

                <Restricted to='dashboard.projectoverview.view'>
                  <ProjectInfoTable
                    data={data.projectData}
                    handleDeleteProjects={handleDeleteProjects}
                    filterOption={filterOption}
                    setFilterOption={handleFilter}
                    isRefetching={isRefetching}
                    handleProjectPlaceholder={handleProjectPlaceholder}
                    authData={authData}
                    initialStep={initialStep}
                    onExit={onExit}
                    steps={steps}
                    stepsEnabled={stepsEnabled}
                  />
                </Restricted>
              </>
            )}
          </PermissionProvider>
        </div>
      )}
      {isLoading && <BlockLoading />}
    </>
  )
}
import { UserAgreement } from './UserAgreement'
import React from 'react'
const DashboardBreadcrumbs: Array<PageLink> = [
  {
    title: 'Supervisor Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const DashboardWrapper: FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle breadcrumbs={DashboardBreadcrumbs}>{t('dashboard')}</PageTitle>
      <IntroJSProvider>
        <DashboardPage />
      </IntroJSProvider>
    </>
  )
}

export { DashboardWrapper }
