import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../modules/dashboard/DashboardWrapper'
import { DashboardWrapperMobile } from '../modules/mobile/dashboard/DashboardWrapperMobile'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { isMobile } from 'react-device-detect'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const ProjectPage = lazy(() => import('../modules/apps/project/ProjectPage'))
  const EmailTemplatePage = lazy(() => import('../modules/apps/email/EmailPage'))
  const navigation = isMobile ? '/installer/install/start' : '/dashboard'
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={navigation} />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route path='mobile' element={<DashboardWrapperMobile />} />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/project/*'
          element={
            <SuspensedView>
              <ProjectPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/email/*'
          element={
            <SuspensedView>
              <EmailTemplatePage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
