import { FC } from 'react'
import { useTranslation } from 'react-i18next'
type Props = {
  columns: Array<any>
  selectColumn: (id: string) => any
  id?: string
}
export const ColumnDropdown: FC<Props> = ({ columns, selectColumn, id }) => {
  const { t } = useTranslation()
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown w-250px w-md-300px collapse position-absolute end-0 top-100'
        id={id}
        data-kt-menu='true'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{t('filter_options')}</div>
        </div>

        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          <div className='overflow-auto mh-255px'>
            {Array.from(columns).map((column: any, index: any) => (
              <div className='mb-4' key={`${column.name} - ${index}`}>
                <div className='d-flex'>
                  <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      defaultChecked={!column.omit}
                      onClick={() => selectColumn(index)}
                    />
                    <span className='form-check-label'>{column.name}</span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
