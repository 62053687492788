import React from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../helpers'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../app/modules/auth'
export function MenuInner() {
  const intl = useIntl()
  const { t } = useTranslation()
  const { currentUser } = useAuth()
  return (
    <>
      <Link to='/dashboard' className='me-10 d-flex align-items-center'>
        <img
          alt='Logo'
          className='h-30px logo'
          src={toAbsoluteUrl('/media/logos/profound_services.png')}
        />
      </Link>

      <MenuItem title={t('dashboard')} to='/dashboard' icon='/media/icons/duotune/art/art002.svg' />
      {currentUser?.role === 'guest' ? (
        ''
      ) : (
        <MenuItem
          title={t('users')}
          to='/apps/user-management'
          icon='/media/icons/duotune/communication/com006.svg'
        />
      )}

      {/* <MenuInnerWithSub
        title='Non-trace'
        to='/apps/non-trace/session-start'
        icon='/media/icons/duotune/communication/com012.svg'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/apps/non-trace/session-start' title='Session Start' hasBullet={true} />
        <AsideMenuItem to='/apps/non-trace/session-start' title='Update Config' hasBullet={true} />
        <MenuItem to='/apps/data-summary/list' title='Summary Data' hasBullet={true} />
        <MenuItem to='/apps/kb-data/list' title='KB Data' hasBullet={true} />
        <MenuItem to='/apps/meta-data/list' title='Meta Data' hasBullet={true} />
        <MenuItem to='/apps/session-end/sessions' title='Session End' hasBullet={true} />
      </MenuInnerWithSub> */}

      {/* <MenuItem
        to='/apps/chart'
        icon='/media/icons/duotune/art/art002.svg'
        title='Metronic default Charts'
      />

      <MenuItem
        to='/apps/suggested-chart'
        icon='/media/icons/duotune/art/art002.svg'
        title='Free Chart library'
      />

      <MenuItem
        to='/apps/paid-chart'
        icon='/media/icons/duotune/art/art002.svg'
        title='Paid Chart library'
      /> */}
      {currentUser && currentUser?.role === 'superadmin' ? (
        <MenuItem
          to='/apps/email'
          icon='/media/icons/duotune/art/art002.svg'
          title={t('email_templates')}
        />
      ) : (
        ''
      )}
    </>
  )
}
