import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'

const DashboardPage: FC = () => {
  return (
    <>
      ------------ installer_splash additional_features------------
      <div className='text-center mt-10 px-2'>
        <h1 className='mb-3'>You have projects pending installation.</h1>
        <p>
          The server is listening for devices switching on. Either place a unit and swipe the
          magnetic switch for the server to identify it, or select a device for installation either
          by serial number or by mount location.
        </p>
        <p>
          If the server detects a unit switching on it will allow you to select the project and
          mounting location automatically
        </p>

        <div className='mt-10 d-flex flex-column'>
          <a href='#' className='btn btn-secondary mb-3'>
            Select mount location
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            Select device
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            Edit installation report
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            Project measurements
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            Project configurations
          </a>
        </div>
      </div>
      ------------ Device_activated_location_assigned ----------
      <div className='text-center mt-10 px-2'>
        <h1 className='mb-3'>A device activation was detected</h1>

        <table className='table lessPadding text-start'>
          <tbody>
            <tr>
              <td>Serial number:</td>
              <td>
                <strong>VIB00034</strong>
              </td>
            </tr>
            <tr>
              <td>Label:</td>
              <td>ConstrName3BG-West</td>
            </tr>
            <tr>
              <td>Project:</td>
              <td>
                <strong>ConstructionName</strong>
              </td>
            </tr>
            <tr>
              <td>Mount Point:</td>
              <td>
                <strong>Building 3 BG west wall</strong>
              </td>
            </tr>
          </tbody>
        </table>

        <div className='mt-10 d-flex flex-column'>
          <a href='#' className='btn btn-secondary mb-3'>
            add photos
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            add description/remarks
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            Confirm
          </a>

          <a href='#' className='btn btn-secondary mt-10 mb-3'>
            Change mount point
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            Edit project setting
          </a>
        </div>
      </div>
      ------------ Device_activated_project_assigned 5 / 8 ----------
      <div className='text-center mt-10 px-2'>
        <h1 className='mb-3'>A device activation was detected</h1>

        <table className='table table-sm text-start'>
          <tbody>
            <tr>
              <td>Serial number:</td>
              <td>
                <strong>VIB00034</strong>
              </td>
            </tr>
            <tr>
              <td>Label:</td>
              <td>ConstrName3BG-West</td>
            </tr>
            <tr>
              <td>Project:</td>
              <td>
                <strong>ConstructionName</strong>
              </td>
            </tr>
            <tr>
              <td>Mount Point:</td>
              <td>
                <strong>Building 3 BG west wall</strong>
              </td>
            </tr>
          </tbody>
        </table>

        <table className='table bg-white border-gray-500 table-striped  border text-start gs-3'>
          <thead>
            <tr>
              <th>Mount locations</th>
              <th>Norm</th>
              <th>C</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Building 3 West Top floor</td>
              <td>DIN4150-3 build.</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Church foundation</td>
              <td>DIN4150-3 build.</td>
              <td>3</td>
            </tr>
          </tbody>
        </table>

        <div className='mt-10 d-flex flex-column'>
          <a href='#' className='btn btn-secondary mb-3'>
            add photos
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            add description/remarks
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            Save/Continue installation
          </a>
        </div>
      </div>
      ------------ Project_redirect_screen 7----------
      <div className='text-center mt-10 px-2'>
        <h1 className='mb-3'>Select Project</h1>

        <div className='mt-5 d-flex flex-column'>
          <a href='#' className='btn btn-secondary mb-3'>
            ConstructionName, Waddinxveen
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            DifferentName, Zwolle
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            ThirdProjectName, Hyderabad
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            AnotherProject, Ho Chi Min City
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            MoreProjects, Medellin
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            EvenMoreProjects1, Kork
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            EvenMoreProjects2, Kork
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            EvenMoreProjects3, Kork
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            EvenMoreProjects4, Kork
          </a>
          <a href='#' className='btn btn-secondary mb-3'>
            EvenMoreProjects5, Kork
          </a>
        </div>
      </div>
      ------------Project_redirect_screen 8----------
      <div className='text-center mt-10 px-2'>
        <h1 className='mb-3'>Select Project</h1>
        <table className='table bg-white border-gray-500 table-striped  border text-start gs-3'>
          <thead>
            <tr>
              <th>Project name</th>
              <th>project location</th>
              <th>End date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ConstructionName</td>
              <td>Waddinxveen</td>
              <td>23 Jan 202</td>
            </tr>
            <tr>
              <td>DifferentName</td>
              <td>DifferentName</td>
              <td>17 Feb 2023</td>
            </tr>

            <tr>
              <td>ThirdProjectName</td>
              <td>Hyderabad</td>
              <td>5 March 20</td>
            </tr>

            <tr>
              <td>AnotherProject</td>
              <td>Ho Chi Min City</td>
              <td>2 Apr 2023</td>
            </tr>

            <tr>
              <td>MoreProjects</td>
              <td>Medellin</td>
              <td>4 Feb 2023</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

const DashboardBreadcrumbs: Array<PageLink> = [
  {
    title: 'Superviser Dashboard',
    path: '',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '/',
    path: '/mobile',
    isSeparator: true,
    isActive: false,
  },
]
const DashboardWrapperMobile: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={DashboardBreadcrumbs}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapperMobile }
