import { FC } from 'react'

type Props = {
  className?: string
}

const BlockLoading: FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <div className='d-flex justify-content-center h-100 w-100 align-items-center'>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    </div>
  )
}

export { BlockLoading }
