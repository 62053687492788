import React, { FC, useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useAuth } from '../auth'
import { updateAgreementAcceptenceValue } from './core/_requests'

const UserAgreement: FC = () => {
  const { currentUser } = useAuth()
  useEffect(() => {
    if (
      currentUser?.accepted_agreement === 'false' ||
      currentUser?.accepted_agreement === null ||
      currentUser?.accepted_agreement === undefined ||
      currentUser?.accepted_agreement === ''
    ) {
      setshow(true)
    } else {
      setshow(false)
    }
  }, [currentUser?.accepted_agreement])
  const object = {
    agreement: false,
  }
  const [show, setshow] = useState(false)
  const { logout } = useAuth()
  const handleDecline = async () => {
    object.agreement = false
    await updateAgreementAcceptenceValue(currentUser?._id, object)
    setshow(false)
    logout()
  }
  const handleAccept = async () => {
    object.agreement = true
    await updateAgreementAcceptenceValue(currentUser?._id, object)
    setshow(false)
    window.location.reload()
  }
  const style = {
    color: 'black',
  }
  return (
    <>
      <div className='modal show'>
        <Modal
          style={{ zIndex: '999999999' }}
          size='xl'
          aria-labelledby='contained-modal-title-vcenter'
          show={show}
          // fullscreen={true}
        >
          <Modal.Header className='d-flex flex-row-reverse border border-0'>
            <button type='button' className='btn btn-sm btn-white' onClick={() => handleDecline()}>
              <i className='fa fa-x' />
            </button>
          </Modal.Header>
          <Modal.Body className='m-5 rounded'>
            <div>
              <div className='text-center'>
                <h1 className='h1'>Data Processing Agreement Profound</h1>
              </div>
              <br />
              <div className='text-black'>
                <div className='fs-7'>
                  {`
                    Thank you for using Profoundportal.online ("Portal"). These Data Processing
                  Agreements (the "Terms") govern your use of the Portal, so please read them
                  carefully before using the Portal. In addition, the Terms are additional to any
                  agreement that you have entered into with us.`}{' '}
                  <br />
                  <br />
                  {`By using the Portal, you agree to be
                  bound by this agreement. If you don't agree to this agreement, do not use the
                  Portal. If you are using the Portal on behalf of an organization (such as your
                  employer), you are agreeing to these Terms for that organization, and are
                  indicating that you have the authority to bind that organization to these Terms.
                  In that case, "you" and "your" will refer to that organization.`}
                </div>
                <br />

                <div>
                  <h2 className='h2 '>
                    <strong>
                      <i className='text-body'>Parties</i>
                    </strong>
                  </h2>

                  <ul>
                    <li>
                      {`User, Guest or Supervisor hereinafter referred to as the: `}
                      <strong>“Controller”;</strong>
                    </li>
                    <br />
                    <li>
                      {`
                      Profound Services B.V. registered with the Dutch Chamber of Commerce under
                      registration number 91057256 and Profound Equipment B.V. registered with the
                      Dutch Chamber of Commerce under number 91045460, both having their registered
                      office at Mozartlaan 46-A in (2742 BN) Waddinxveen, and lawfully represented
                      by Mr. A. Ourensma (hereinafter referred to as the: `}
                      <strong>“Processor”</strong>
                      {`);`}
                    </li>
                  </ul>
                </div>

                <div>
                  {`
                  If the processing of personal data relates to the provision of the platform and
                  training, the Controller's relevant counterparty is Profound Services B.V.. If the
                  processing of personal data relates to the provision of hardware, the relevant
                  counterparty of the Controller is Profound Equipment B.V..`}{' '}
                  <br />
                  <br />
                  {`hereinafter referred to collectively as the "Parties" and individually as "Party",`}
                </div>
                <br />

                <div>
                  <strong className='strong'>
                    <strong>
                      <i className='text-body'>Considering that</i>
                    </strong>
                  </strong>

                  <ul>
                    <li className='fs-7'>
                      Controller has access to personal data of various data subjects;
                    </li>
                    <li className='fs-7'>
                      {`
                      Processor is a supplier that offers hardware in the form of professional pile
                      testing equipment and vibration monitoring equipment for ground vibrations for
                      the benefit of construction projects. Processor also provides a platform
                      (Software-as-a-Service) where measurements can be monitored online and the
                      data can be displayed in graphs;`}
                    </li>
                    <li className='fs-7'>
                      {`Controller and Processor have entered into one or more Agreements for the purposes of the foregoing (hereinafter: ''the Agreement(s)'');`}
                    </li>
                    <li className='fs-7'>{`Controller specifies the purposes and means of processing, which are subject to the conditions referred to herein;`}</li>
                    <li className='fs-7'>{`Processor is also willing to comply with obligations regarding security and other aspects of the General Data Protection Regulation (hereinafter: ''GDPR'');`}</li>
                    <li className='fs-7'>{`Personal data is considered as personal data within the meaning of Article 4(1) of the GDPR;`}</li>
                    <li className='fs-7'>{`Controller may be considered the controller within the meaning of Article 4(7) of the GDPR;`}</li>
                    <li className='fs-7'>{`Processor may be regarded as the processor within the meaning of Article 4(8) of the GDPR;`}</li>
                    <li className='fs-7'>{`Partly in view of the requirement of Article 28(1) of the GDPR, the parties wish to lay down their rights and obligations in writing by means of this Data Processing Agreement (hereinafter: "Data Processing Agreement");`}</li>
                  </ul>
                </div>
                <br />
                <div>
                  <strong className='strong text-black mb-3'>
                    <strong>
                      <i className='text-body'>Have agreed on the following:</i>
                    </strong>
                  </strong>
                  <br />

                  <div className='mt-5'>
                    <strong className='text-black'>Article 1. Purposes of processing</strong>
                  </div>

                  <table className='border-none table ' style={style}>
                    <tbody>
                      <tr className=''>
                        <td className='d-inline-flex align-top'>1.1</td>
                        <td>{`Under the terms of this Data Processing Agreement, Processor undertakes to process personal data on the instructions of Controller. Processing will take place exclusively for the purposes specified in the Agreement(s), and those purposes which are reasonably related to them, or which are determined by further agreement.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>1.2</td>
                        <td className=''>
                          {`The personal data (to be) processed by the Processor in the context of the Agreement and the
                              categories of data subjects from whom it originates depend on the service provided by the
                              Processor under the Agreement as specified in Annex 1. Processor provides a Software-as-a-
                              Service platform and offers hardware to monitor ground vibrations for the benefit of
                              construction projects. By providing these services, Processor gains access to personal data of
                              Controller. Processor processes all categories of personal data of all categories of data subject(s)
                              processed in the context of the platform. The Processor will not process the personal data for
                              any purpose other than as determined by the Controller. Controller shall inform Processor of
                              the processing purposes insofar as they are not already set out in this Data Processing
                              Agreement.`}
                        </td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>1.3</td>
                        <td>{`The personal data to be processed on the instructions of the Controller will remain the property
                              of the Controller and/or the data subjects involved.`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <strong className='text-black'>Article 2. Obligations of Processor</strong>

                  <table className='border-none table' style={style}>
                    <tbody>
                      <tr>
                        <td className='d-inline-flex align-top'>2.1</td>
                        <td>{`With regard to the processing referred to in Article 1, Processor shall ensure compliance with
                              the conditions that are imposed on the processing of personal data by the Processor in its role,
                              based on the applicable laws and regulations in the field of personal data protection.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>2.2</td>
                        <td>{`The Processor will inform the Controller at the latter’s first request of the measures it has taken
                              regarding its obligations under this Data Processing Agreement and relevant privacy laws and
                              regulations.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>2.3</td>
                        <td>{`The obligations of Processor that arise from this Data Processing Agreement also apply to those
                              who process personal data under the supervision of Processor, including but not limited to
                              employees, in the broadest sense of the word.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>2.4</td>
                        <td>{`Processor will notify the Controller immediately if, in its opinion, an instruction from the
                              Controller is in breach of the legislation referred to in paragraph 1.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>2.5</td>
                        <td>{`Processor will provide the Controller with the necessary cooperation if a data protection impact
                              assessment and any prior consultation with the Data Protection Authority are required in
                              connection with the processing. Processor may charge Controller reasonable costs for this.`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className=''>
                  <strong className='strong text-black'>
                    Article 3. Transfer of Personal Data
                  </strong>

                  <table className='border-none table' style={style}>
                    <tbody>
                      <tr>
                        <td className='d-inline-flex align-top'>3.1</td>
                        <td>
                          {`Processor may process the personal data in countries within the European Economic Area
                              ("EEA"). In addition, Processor may also transfer the personal data to a country outside the
                              EEA, provided that such country ensures an adequate level of protection, and it complies with
                              the other obligations incumbent on it under this Data Processing Agreement and the GDPR.
                              `}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <strong className='strong text-black'>
                    Article 4. Allocation of responsibility
                  </strong>

                  <table className='border-none table' style={style}>
                    <tbody>
                      <tr>
                        <td className='d-inline-flex align-top'>4.1</td>
                        <td>{`The permitted processing is carried out within a (semi-)automated environment.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>4.2</td>
                        <td>{`The Processor is solely responsible for processing the personal data under this Data Processing Agreement, in accordance with the instructions of the Controller and under the explicit (final) responsibility of the Controller. `}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>4.3</td>
                        <td>{`Processor will provide all reasonable assistance to Controller in fulfilling its obligations under Articles 32 to 36 of the GDPR, such as supporting the performance of a Data Protection Impact Assessment ("DPIA") or prior consultation with the regulator, should this be necessary.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>4.4</td>
                        <td>{`	Controller guarantees that the content, use and commissioning of the processing of the personal data referred to in this Data Processing Agreement are not unlawful and do not infringe any rights of third parties.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>4.5</td>
                        <td>{`The Processor shall notify the Controller if, in its opinion, the instructions contravene the applicable legislation governing the processing of personal data or are otherwise unreasonable.`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <strong className='strong text-black'>
                    Article 5. Use of third parties or subcontractors
                  </strong>
                  <table className='border-none table' style={style}>
                    <tbody>
                      <tr>
                        <td className='d-inline-flex align-top'>5.1</td>
                        <td>
                          {
                            'Controller hereby grants the Processor permission to use third parties (sub-processors) when processing personal data pursuant to this Data Processing Agreement, subject to the applicable privacy legislation. Controller has a right to object to the use of a specific reported third party. An overview of these third parties can be requested from the Processor at the written request of the Controller.'
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>5.2</td>
                        <td>{`	In any event, Processor shall ensure that these sub-processors take on, in writing, at least comparable duties as agreed between the Controller and the Processor.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>5.3</td>
                        <td>{`The Processor guarantees correct compliance with these obligations by these sub-processors and, in the event of errors by these sub-processors, will itself be liable to the Controller for all damage as if it had made the error(s) itself.`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <strong className='strong text-black'>Article 6. Duty to report</strong>

                  <table className='border-none table mb-0' style={style}>
                    <tbody>
                      <tr>
                        <td className='d-inline-flex align-top'>6.1</td>
                        <td>
                          {
                            'Controller is always responsible for reporting a security incident and/or data leak (which is understood to mean: a breach of security resulting in the accidental or unlawful destruction, loss, alteration or unauthorised provision of or access to data that has been transmitted, stored or otherwise processed) to the Data Protection Authority and/or the data subjects concerned. To enable Controller to comply with this legal obligation, Processor will inform Controller of the security incident and/or data leak within 48 hours of becoming aware of the leak.'
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>6.2</td>
                        <td>
                          {`	The duty of notification includes in any case the reporting of the fact that a leak has occurred. In addition, the duty of notification includes:`}
                          <br />
                          <ul className='mt-3'>
                            <li>{`the nature and infringement of personal data, where possible with an indication of the categories of data subjects and personal data concerned and, in approximate terms, the number of data subjects and personal data concerned;`}</li>
                            <li>{`the name and contact details of the data protection officer or other contact point where more information can be obtained;`}</li>
                            <li>{`the likely consequences of the personal data breach;`}</li>
                            <li>{`the measures that Processor has proposed or taken to address the Personal Data Breach, including, where appropriate, the measures to mitigate any adverse effects.`}</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='mt-0 pt-0'>
                  <strong className='strong text-black'>Article 7. Security</strong>

                  <table className='border-none table' style={style}>
                    <tbody>
                      <tr>
                        <td className='d-inline-flex align-top'>7.1</td>
                        <td>{`Processor shall make sufficient efforts to take appropriate technical and organisational measures regarding the processing of personal data to be carried out, against loss or against any form of unlawful processing (such as unauthorised access, impairment, alteration or transmission of the personal data).`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>7.2</td>
                        <td>{`Processor does not guarantee that the security is effective in all circumstances. If there is no explicit description of the security in the Data Processing Agreement, the Processor will endeavour to ensure that the security meets a level that is not unreasonable, given the state of the art, the sensitivity of the personal data and the costs involved in implementing the security.`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <strong className='strong text-black'>
                    Article 8. Handling of requests from data subjects
                  </strong>

                  <table className='border-none table' style={style}>
                    <tbody>
                      <tr>
                        <td className='d-inline-flex align-top'>8.1</td>
                        <td>{`In the event that a data subject makes a request to exercise his/her legal rights (Article 15-22 of the GDPR) to Processor, Processor will forward the request to Controller, and Controller will handle the request further. Processor may inform the data subject accordingly. However, if both Parties deem it more practical, they may jointly decide that Processor will handle and fulfil the request. Controller shall however retain final responsibility on the request. `}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>8.2</td>
                        <td>{`In the event that a data subject makes a request to the Controller to exercise one of his or her legal rights in respect of personal data, the Processor will, if required by the Controller, provide technical support in executing the request to the extent possible and reasonable. Processor may charge Controller reasonable costs for this.`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <strong className='strong text-black'>
                    Article 9. Secrecy and confidentiality
                  </strong>

                  <table className='border-none table' style={style}>
                    <tbody>
                      <tr>
                        <td className='d-inline-flex align-top'>9.1</td>
                        <td>{`All personal data that the Processor receives from the Controller and/or collects itself in the context of this Data Processing Agreement is subject to an obligation of confidentiality vis-à-vis third parties.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>9.2</td>
                        <td>{`This secrecy obligation will not apply insofar as the Controller has given explicit permission to provide the information to third parties, if providing the information to third parties is logically necessary in view of the nature of the assignment given and the execution of this Data Processing Agreement, or if there is a legal obligation to provide the information to a third party. `}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>9.3</td>
                        <td>{`If Processor is required by a legal obligation or court order to provide personal data processed on behalf of Controller to a third party, Processor will inform Controller of this, unless this is prohibited by law.`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <strong className='strong text-black'>Article 10. Audit</strong>

                  <table className='border-none table' style={style}>
                    <tbody>
                      <tr>
                        <td className='d-inline-flex align-top'>10.1</td>
                        <td>{`The Controller is entitled to have audits carried out by an independent expert third party, who is bound by confidentiality, to check compliance with this Data Processing Agreement.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>10.2</td>
                        <td>{`This audit will take place no more than once a year and will only take place if there is a concrete suspicion of misuse of personal data that is demonstrated by the Controller. The audit initiated by the Controller will take place two weeks after prior notification by the Controller. `}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>10.3</td>
                        <td>{`Processor shall cooperate with the audit and make all information, including supporting data such as system logs, and employees reasonably relevant for the audit available within a reasonable period, with a maximum of two weeks being reasonable.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>10.4</td>
                        <td>{`The findings as a result of the audit carried out will be assessed by Processor and may, at Processor's discretion and in the manner determined by Processor, be implemented by Processor.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>10.5</td>
                        <td>{`The costs of the audit shall be borne by the Controller.`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <strong className='strong text-black'>Article 11. Liability</strong>

                  <table className='border-none table' style={style}>
                    <tbody>
                      <tr>
                        <td className='d-inline-flex align-top'>11.1</td>
                        <td>{`As regards the liability of the Parties for damages as a result of an attributable failure in the performance of the Data Processing Agreement, or in tort or otherwise, the regulation of liability agreed in the Agreement shall apply. This shall also apply to liability of the Processor as a result of faults of sub-processors engaged by it.  `}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>11.2</td>
                        <td>{`Processor is explicitly not liable for any damage of Controller as a result of a fine imposed by any of the national supervisory authorities, including the Data Protection Authority, in the context of statutory reporting obligations, among other things.`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <strong className='strong text-black'>
                    Article 12. Duration and termination
                  </strong>

                  <table className='border-none table' style={style}>
                    <tbody>
                      <tr>
                        <td className='d-inline-flex align-top'>12.1</td>
                        <td>{`This Data Processing Agreement shall come into effect upon signature by the Parties and on the date of the last signature.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>12.2</td>
                        <td>{`This Data Processing Agreement is entered into for the same duration as the Agreement(s).`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>12.3</td>
                        <td>{`This Data Processing Agreement may be amended in the same way as the Agreement.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>12.4</td>
                        <td>{`After termination of the Data Processing Agreement, the Processor shall destroy all personal data present in its possession, unless the Parties agree otherwise.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>12.5</td>
                        <td>{`If one or more provisions of the Data Processing Agreement prove to be invalid, the Data Processing Agreement will remain in force for the rest. The parties shall then consult on the provisions that are not legally valid, to make a replacement arrangement that is legally valid and as far as possible in keeping with the purport of the arrangement to be replaced.`}</td>
                      </tr>
                      <tr>
                        <td className='d-inline-flex align-top'>12.6</td>
                        <td>{`The parties will provide each other with full cooperation to amend this Data Processing Agreement and make it suitable for any new or amended privacy legislation.`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <strong className='strong text-black text-uppercase'>
                    ANNEX 1: PROCESSED PERSONAL DATA AND THE DATA SUBJECTS{' '}
                  </strong>
                  <p
                    className='mt-3'
                    style={{ fontSize: '11.96px' }}
                  >{`The Processor will process, within the framework of the Agreement, the (sensitive) personal data as mentioned in this annex. The categories of data subjects to whom the personal data relates, are also mentioned in this annex. `}</p>
                  <strong className='mt-5 text-black'>Personal data:</strong>
                  <p className='mb-0 ' style={{ fontSize: '11.96px' }}>
                    When Processor provides the platform:{' '}
                  </p>
                  <div className='ms-4'>
                    <ul>
                      <li className='' style={{ fontSize: '11.96px' }}>
                        Ground measurement data (if traceable back to individuals){' '}
                      </li>
                      <li className='' style={{ fontSize: '11.96px' }}>
                        Basic identification information{' '}
                      </li>
                      <ul>
                        <li className='' style={{ fontSize: '11.96px' }}>
                          Name{' '}
                        </li>
                        <li className='' style={{ fontSize: '11.96px' }}>
                          Phone number
                        </li>
                        <li className='' style={{ fontSize: '11.96px' }}>
                          Email
                        </li>
                      </ul>
                      <br />
                      <li className='' style={{ fontSize: '11.96px' }}>
                        Professional information{' '}
                      </li>
                      <ul>
                        <li className='' style={{ fontSize: '11.96px' }}>
                          Job title (if applicable){' '}
                        </li>
                        <li className='' style={{ fontSize: '11.96px' }}>
                          Company name{' '}
                        </li>
                      </ul>
                      <br />
                      <li className='' style={{ fontSize: '11.96px' }}>
                        Technical data{' '}
                      </li>
                      <ul>
                        <li className='' style={{ fontSize: '11.96px' }}>
                          IP address{' '}
                        </li>
                        <li className='' style={{ fontSize: '11.96px' }}>
                          Geographical location{' '}
                        </li>
                        <li className='' style={{ fontSize: '11.96px' }}>
                          Serial numbers per customer{' '}
                        </li>
                      </ul>
                    </ul>
                  </div>

                  <p className='mb-0 mt-5 ' style={{ fontSize: '11.96px' }}>
                    When Processor provides training to employees of Controller:
                  </p>
                  <div className='ms-4'>
                    <ul>
                      <li className='' style={{ fontSize: '11.96px' }}>
                        Basic identification information of participants{' '}
                      </li>
                      <ul>
                        <li className='' style={{ fontSize: '11.96px' }}>
                          Name
                        </li>
                        <li className='' style={{ fontSize: '11.96px' }}>
                          Phone number
                        </li>
                        <li className='' style={{ fontSize: '11.96px' }}>
                          Email
                        </li>
                      </ul>
                    </ul>
                  </div>
                  <div className='ms-4'>
                    <strong className='text-black ' style={{ fontSize: '11.96px' }}>
                      Categories of data subjects:
                    </strong>
                    <ul>
                      <li className='' style={{ fontSize: '11.96px' }}>
                        Customer’s clients{' '}
                      </li>
                      <li className='' style={{ fontSize: '11.96px' }}>
                        Individuals related to the measured sites (if applicable){' '}
                      </li>
                      <li className='' style={{ fontSize: '11.96px' }}>
                        Participants of the training sessions{' '}
                      </li>
                    </ul>
                  </div>
                </div>
                {/**
                 *
                 *
                 *
                 *
                 *
                 */}
              </div>
            </div>

            <div className='d-flex flex-center gap-3 mt-8'>
              <button className='btn btn-sm btn-primary' onClick={() => handleAccept()}>
                I agree to the processing agreement
              </button>
              {/* <button className='btn btn-sm btn-light' onClick={() => handleDecline()}>
                Decline
              </button> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export { UserAgreement }
