import { FC, useEffect, useState } from 'react'
import { SuperTableStatic } from '../../../components/tables/SuperTableStatic'
import { ColumnDropdown } from '../../../components/tables/ColumnDropdown'
import { KTSVG } from '../../../../_metronic/helpers'
import { Loading } from '../../../components/loaders/Loading'
import { useTranslation } from 'react-i18next'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Tooltip } from 'react-bootstrap'
import React from 'react'
import { TableColumn } from 'react-data-table-component'
import { DeviceDataRow } from '../core/_models'
import { dateToString } from '../../../helpers/DateHelper'
import Icon from '@mdi/react'
import { useAuth } from '../../auth'

// Displays timezones correctly afaik
import {
  mdiBatteryCharging,
  mdiBattery,
  mdiBatteryOutline,
  mdiBattery50,
  mdiBattery10,
  mdiBatteryCharging50,
  mdiBatteryCharging10,
} from '@mdi/js'

type Props = {
  data: Array<any>
  projectData: Array<any>
  isRefetching?: boolean
  handleStepEnabled?: () => void
  title: string
  showCheckbox: boolean
  showConfigStatus: boolean
}

const DeviceInfoTable: FC<Props> = ({
  data,
  isRefetching,
  handleStepEnabled,
  title,
  showCheckbox = true,
  showConfigStatus = false,
  projectData,
}) => {
  const { t } = useTranslation()
  const [id] = useState<string>('collapseTarget1')
  const { currentUser } = useAuth()
  let isLoading = isRefetching || false
  const handleSelect = (id: any) => {
    columnData[id].omit = !columnData[id].omit
    setcolumnData([...columnData])
    isLoading = false
  }

  const getProjectIdByNumber = (projectNumber: string): string | null => {
    const project = projectData.find((proj) => proj.project_number == projectNumber)
    return project ? project._id : null
  }

  const getProjectIdByName = (projectName: string): string | null => {
    const project = projectData.find((proj) => proj.name === projectName)
    return project ? project._id : null
  }

  const DeviceColumns: TableColumn<DeviceDataRow>[] = [
    {
      id: 'ID',
      name: t('id_'),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        const isNumeric = (value: string) => /^\d+$/.test(value)
        if (isNumeric(a._id) && isNumeric(b._id)) {
          return parseInt(a._id, 10) - parseInt(b._id, 10) // Numerical sort
        } else {
          return a._id.localeCompare(b._id, undefined, { sensitivity: 'base' }) // String sort
        }
      },
      width: '150px',
      omit: false,
      selector: (row) => row._id,
      cell: (row) => <div key={row._id}>{row._id}</div>,
    },
    {
      id: 'project',
      name: t('project_'),
      sortable: true,
      sortFunction: (a: any, b: any) => a.project_id - b.project_id,
      width: '150px',
      omit: false,
      selector: (row) => (row.project_id ? row.project_id : '-'),
      cell: (row) => {
        const projectId = getProjectIdByNumber(row.project_id)
        if (!projectId) {
          return <div key={row._id}>{row.project_id}</div>
        }
        const href =
          currentUser?.role === 'guest'
            ? `/apps/project/measurement/${projectId}`
            : `/apps/project/status/${projectId}`
        return (
          <a href={href} key={row._id}>
            {row.project_id}
          </a>
        )
      },
    },
    {
      id: 'project_name',
      name: t('project_name_'),
      sortable: true,
      sortFunction: (a: DeviceDataRow, b: DeviceDataRow) => {
        const nameA = a.project_name === '-' ? '' : a.project_name
        const nameB = b.project_name === '-' ? '' : b.project_name

        return nameA.localeCompare(nameB, undefined, { sensitivity: 'base' })
      },
      width: '250px',
      omit: false,
      selector: (row) => (row.project_name ? row.project_name : '-'),
      cell: (row) => {
        const projectId = getProjectIdByName(row.project_name)
        if (!projectId) {
          return <div key={row._id}>{row.project_name}</div>
        }
        const href =
          currentUser?.role === 'guest'
            ? `/apps/project/measurement/${projectId}`
            : `/apps/project/status/${projectId}`
        return (
          <a href={href} key={row._id}>
            {row.project_name}
          </a>
        )
      },
    },
    {
      /**
       * @brief Possible battery icons:
       *
       * https://www.v0.app/icon/mdi/battery
       * https://www.v0.app/icon/tabler/battery
       * https://fontawesome.com/v5/icons/battery-full?f=classic&s=solid
       * https://lucide.dev/icons/battery-charging
       * https://pictogrammers.com/library/mdi/icon/battery/
       *
       * @todo Will we use color or not? Might be too much combined with the sync status
       */
      id: 'charge',
      name: t('charge_'),
      sortable: true,
      sortFunction: (a: any, b: any) => a.charge - b.charge,
      width: '150px',
      omit: false,
      selector: (row) => Math.floor(row.charge),
      cell: (row) => {
        let BatteryIcon = mdiBatteryOutline
        let color = 'rgb(241, 65, 108)'
        let tooltipText = `${Math.floor(row.charge)}% - Disconnected`

        if (row.supply === 'connected (charger)') {
          // Handle connected (charging) state
          if (row.charge > 80) {
            BatteryIcon = mdiBatteryCharging
            color = 'rgb(80, 205, 137)'
            tooltipText = `${Math.floor(row.charge)}% - Charging`
          } else if (row.charge > 30 && row.charge <= 80) {
            BatteryIcon = mdiBatteryCharging50
            color = 'rgb(255, 199, 0)'
            tooltipText = `${Math.floor(row.charge)}% - Charging`
          } else if (row.charge <= 30) {
            BatteryIcon = mdiBatteryCharging10
            color = 'rgb(241, 65, 108)'
            tooltipText = `${Math.floor(row.charge)}% - Charging`
          }
        } else {
          // Handle disconnected state
          if (row.charge > 80) {
            BatteryIcon = mdiBattery
            color = 'rgb(80, 205, 137)'
            tooltipText = `${Math.floor(row.charge)}% - Disconnected`
          } else if (row.charge > 30 && row.charge <= 80) {
            BatteryIcon = mdiBattery50
            color = 'rgb(255, 199, 0)'
            tooltipText = `${Math.floor(row.charge)}% - Disconnected`
          } else if (row.charge > 10 && row.charge <= 30) {
            BatteryIcon = mdiBattery10
            color = 'rgb(241, 65, 108)'
            tooltipText = `${Math.floor(row.charge)}% - Low Battery`
          } else if (row.charge <= 10) {
            BatteryIcon = mdiBatteryOutline
            color = 'rgb(241, 65, 108)'
            tooltipText = `${Math.floor(row.charge)}% - Critical Battery`
          }
        }

        return (
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id={`tooltip-${row._id}`}>{tooltipText}</Tooltip>}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
              }}
            >
              <Icon path={BatteryIcon} size={1} color={color} />
            </div>
          </OverlayTrigger>
        )
      },
    },
    {
      id: 'predicted_cap',
      name: t('predicted_cap_'),
      width: '150px',
      omit: false,
      sortable: true,
      selector: (row) => '-',
    },
    {
      id: 'last_contact',
      name: t('last_contact_'),
      width: '150px',
      sortable: true,
      sortFunction: (a: DeviceDataRow, b: DeviceDataRow) => {
        if (!a.last_contact_timestamp) return -1
        if (!b.last_contact_timestamp) return 1
        if (
          a.last_contact_timestamp.includes('Today') &&
          b.last_contact_timestamp.includes('Today')
        ) {
          const aTime = parseInt(a.last_contact_timestamp.split(' ')[1].replace(':', ''), 10)
          const bTime = parseInt(b.last_contact_timestamp.split(' ')[1].replace(':', ''), 10)
          return aTime - bTime
        }

        return (
          new Date(a.last_contact_timestamp).getTime() -
          new Date(b.last_contact_timestamp).getTime()
        )
      },
      omit: false,
      selector: (row) =>
        `${row.last_contact_timestamp ? dateToString(row.last_contact_timestamp) : '-'}`,
    },
    {
      id: 'next_contact',
      name: t('next_contact_'),
      width: '150px',
      sortable: true,
      sortFunction: (a: DeviceDataRow, b: DeviceDataRow) => {
        if (!a.next_contact) return -1
        if (!b.next_contact) return 1
        if (a.next_contact.includes('Today') && b.next_contact.includes('Today')) {
          const aTime = parseInt(a.next_contact.split(' ')[1].replace(':', ''), 10)
          const bTime = parseInt(b.next_contact.split(' ')[1].replace(':', ''), 10)
          return aTime - bTime
        }

        return new Date(a.next_contact).getTime() - new Date(b.next_contact).getTime()
      },
      omit: false,
      selector: (row) => `${row.next_contact ? dateToString(row.next_contact) : '-'}`,
    },
    {
      id: 'status',
      name: t('status_'),
      sortable: true,
      width: '200px',
      omit: false,
      selector: (row) => row.device_status,
    },
    {
      id: 'peak_vb',
      name: t('peak_vb_'),
      sortable: true,
      sortFunction: (a: any, b: any) => a.peak_vb - b.peak_vb,
      width: '150px',
      omit: false,
      selector: (row) => `${(Math.round(row.peak_vb * 100) / 100).toFixed(2)} mm/s`,
    },
    {
      id: 'fmg',
      name: t('fmg_'),
      sortable: true,
      sortFunction: (a: any, b: any) => a.fmg - b.fmg,
      width: '80px',
      omit: false,
      selector: (row) => `${(Math.round(row.fmg * 100) / 100).toFixed(2)} Hz`,
    },
  ]
  const [columnData, setcolumnData] = useState(DeviceColumns)
  const currentURL = window.location.href

  const renderTooltip = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      {currentURL.includes('apps/project/create')
        ? t('device_table_3')
        : currentURL.includes('/dashboard')
        ? t('device_table_1')
        : t('device_table_15')}
    </Tooltip>
  )
  return (
    <>
      <div className='flex-grow-1 mt-10'>
        <div className='d-flex justify-content-between align-items-start flex-wrap mb-0'>
          <div className='d-flex flex-column mb-0'>
            <div className='d-flex align-items-center mb-0'>
              <span className='text-black-600 text-hover-primary fs-1 fw-bolder me-1 mb-0'>
                {t('device_overview')}
              </span>
              <OverlayTrigger
                placement='bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <button className='btn p-0 rounded-circle' type='button'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen045.svg'
                    className='svg-icon-1 fw-bold text-hover-primary'
                  />
                </button>
              </OverlayTrigger>
            </div>
            <div className='d-flex align-items-right mb-0'></div>
          </div>
          <div className='d-flex my-0'>
            <button
              type='button'
              className='btn btn-sm btn-secondary mb-4 mr-4 assign_project '
              title='Coming soon'
            >
              {t('assign_project')}
            </button>

            <div className='dropdown position-relative'>
              <button
                type='button'
                className='btn btn-primary btn-sm btn-icon ms-2 filter_device'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom-end'
                data-bs-target='#collapseTarget1'
                data-bs-toggle='collapse'
                data-bs-menu-trigger='click'
                data-bs-menu-placement='bottom-end'
                data-bs-menu-flip='bottom-end'
                // title='Comming Soon'
              >
                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
              </button>

              <ColumnDropdown columns={columnData} selectColumn={handleSelect} id={id} />
            </div>
          </div>
        </div>
      </div>
      <div className='card table-responsive'>
        {isLoading && <Loading></Loading>}
        {!isLoading && (
          <div className='device_table'>
            <SuperTableStatic
              data={data}
              pagination={true}
              columns={columnData}
              height={''}
              selectableRows={true}
              showCheckbox={showCheckbox}
              showConfigStatus={showConfigStatus}
            ></SuperTableStatic>
          </div>
        )}
      </div>
    </>
  )
}

export { DeviceInfoTable }
