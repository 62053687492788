import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const loginSchema = Yup.object().shape({
  email: Yup.string().required(t('email_or_username_required')),
  password: Yup.string()
    .min(3, t('minimum_3_symbols'))
    .max(50, t('maximum_50_symbols'))
    .required(t('password_is_required')),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [placeholder, setPlaceholder] = useState({
    email: t('email_or_username'),
    password: t('password'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password)
        saveAuth(auth)
        const { data: user } = await getUserByToken(auth.jwt)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('incorrect credentials')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{t('sign_in')} </h1>
      </div>
      {/* end::Heading */}

      {/* begin::Maintenance Message */}
      <div className='mb-10 alert alert-warning'>
        <div className='alert-text font-weight-bold'>
          {t(
            'Database maintenance is currently in progress. As a result, performance may be temporarily impacted.'
          )}
        </div>
      </div>
      {/* end::Maintenance Message */}

      {/* begin::Message */}
      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div></div>
      )}
      {/* end::Message */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{t('email_or_username')}</label>
        <input
          placeholder={placeholder.email}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='text'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{t('password')}</label>
          </div>
        </div>
        <input
          type={'password'}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          placeholder={t('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{t('continue')}</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* begin::Link */}
      <Link
        to='/auth/forgot-password'
        className='link-primary fs-6 fw-bolder'
        style={{ marginLeft: '5px' }}
      >
        {t('forgot_password')}
      </Link>
      {/* end::Link */}
    </form>
  )
}
