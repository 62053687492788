import axios, { AxiosResponse } from 'axios'
import { Response } from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_REST_API_URL
const DASHBOARD_URL = `${API_URL}/dashboard`
const DeviceTable_With_ProfileId = `${API_URL}/project/device`
const ProjectConfigration_With_ProjectId = `${API_URL}/project/getDatesForProject`

const getDeviceDataRows = (filter: any): Promise<any> => {
  return axios.get(`${DASHBOARD_URL}`, { params: filter }).then((d: AxiosResponse<any>) => d.data)
}

const setDeviceTableWithProfileId = async (id: any): Promise<any> => {
  await axios.delete(`${DeviceTable_With_ProfileId}/${id}`)
}

const getProjectConfigrationNameWithDate = async (id: any): Promise<any> => {
  try {
    const response = await axios.get(`${ProjectConfigration_With_ProjectId}/${id}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

const getAuthData = (): Promise<any> => {
  return axios.get(`${API_URL}/auth/me`).then((response: AxiosResponse<any>) => response.data)
}
const updateAuthData = (filterData: any): Promise<any> => {
  return axios
    .post(`${API_URL}/auth/update-placeholder-screen`, filterData)
    .then((res: AxiosResponse<any>) => res.data)
    .then((res: Response<any>) => res.data)
}
const updateAgreementValue = (id: any, data: any): Promise<any> => {
  return axios
    .patch(`${API_URL}/users/aggrement/${id}`, data)
    .then((res: AxiosResponse<any>) => res.data)
}

const updateAgreementAcceptenceValue = (id: any, data: any): Promise<any> => {
  return axios
    .post(`${API_URL}/users/agrement-acceptence/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data)
}
export {
  getDeviceDataRows,
  getAuthData,
  updateAuthData,
  setDeviceTableWithProfileId,
  getProjectConfigrationNameWithDate,
  updateAgreementValue,
  updateAgreementAcceptenceValue,
}
